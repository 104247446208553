import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { goal: String, props: Object };

  send(event) {
    const isLinkOpeningInCurrentContext =
      (!this.element.target || // empty target resolves to _self by default
        this.element.target.match(/^_(self|parent|top)$/i)) &&
      !event.ctrlKey &&
      !event.metaKey &&
      !event.shiftKey;

    const defaultProps = {
      path: document.location.pathname,
    };

    const plausibleEventProps = {
      ...defaultProps,
      ...this.propsValue,
    };

    plausible(this.goalValue, { props: plausibleEventProps });
    if (isLinkOpeningInCurrentContext) {
      event.preventDefault();
      // delay the execution of new page load to let plausible event to be sent
      setTimeout(() => {
        window.location = this.element.href;
      }, 150);
    }
  }
}
