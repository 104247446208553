import { Controller } from "@hotwired/stimulus";

const elementClasses = {
  question: "flex flex-col quiz-question mb-8",
  questionText: "mb-4 prose prose-lg font-serif desktop:prose-base",
  figure: "question-image-container mb-6 desktop:mb-10",
  img: "w-full",
  heading: "quiz-question-heading text-xl font-bold text-gray-800",
  clueContainer: "quiz-answers flex flex-col gap-6 mb-4",
  clue: `quiz-clue w-full bg-beige p-2 pb-4 rounded-lg drop-shadow-md`,
  clueText: "clue-text invisible mt-0",
  overlay:
    "clue-overlay absolute flex inset-0 rounded-lg bg-green hover:bg-black cursor-pointer",
  overlayText: "w-full mb-0 self-center text-center text-white font-bold",
  correctionBox: "mt-2",
  icon: "inline-block w-6 h-6 mr-2 p-1",
};

export default class extends Controller {
  static targets = ["nextButton", "clue", "next"];

  quiz;
  quizContainer;
  currentCategory = 0;
  currentClue = 0;
  categories = [];
  // array of arrays containing the clues to each category by index
  clues = [];
  clueContainer;
  answers = [];

  nextButton;
  clueButton;

  connect() {
    this.setup();
  }

  setup() {
    // read quiz body data
    this.quiz = document.querySelector("#quiz-component");
    this.quizContainer = document.querySelector("#quiz-question-container");
    // clear the innerHTML to remove any duplicates from the user navigating using the history
    this.quizContainer.innerHTML = "";

    const answersDiv = document.createElement("div");
    answersDiv.innerHTML = this.quizContainer.dataset.quizAnswers;

    answersDiv
      .querySelectorAll("li")
      .forEach((el, i) => (this.answers[i] = el.innerText));

    // element where we parse quiz elements
    const virtualDiv = document.createElement("div");
    virtualDiv.innerHTML = this.quizContainer.dataset.quizBody;

    const questionLength = virtualDiv.querySelectorAll("h2").length;
    let counter = -1;
    let clueCounter = 0;

    virtualDiv.childNodes.forEach((node) => {
      if (node.nodeName === "H2") {
        // increment counter
        counter++;
        clueCounter = 0; // reset clueCounter

        // create div element to wrap the question
        const questionWrapper = document.createElement("div");
        questionWrapper.className = elementClasses.question;

        this.categories[counter] = questionWrapper; // save reference to this element
        this.clues[counter] = []; // initialize clue array for this category

        // replace the h2 text with a question counter
        node.innerText = `Kategori ${counter + 1} av ${questionLength}: ${
          node.innerText
        }`;
        node.className = elementClasses.heading;
        node.dataset.target = "question";
        node.dataset.question = counter;

        // add text to wrapper
        questionWrapper.appendChild(virtualDiv.removeChild(node));

        // add question to quiz flow
        this.quizContainer.appendChild(questionWrapper);
      } else if (node.nodeName === "H3") {
        const p = document.createElement("p");
        p.innerText = node.innerText;
        p.className = elementClasses.questionText;

        this.quizContainer.lastChild.appendChild(p);
      } else if (node.nodeName === "UL") {
        const cluesForCategory = document.createElement("div");
        cluesForCategory.className = elementClasses.clueContainer;

        // transform each list item into a button with the correct data attributes
        node.querySelectorAll("li").forEach((li) => {
          const clueToCreate = document.createElement("div");
          const textNode = document.createElement("p");

          // add text to inner node to later set to invisible and preserve formatting
          textNode.innerHTML = li.innerHTML;
          textNode.className = elementClasses.clueText;

          const overlay = document.createElement("div");
          overlay.className = elementClasses.overlay;

          // add interactivity
          // new binding needs to happen here as to create a new reference before adding it to the listener
          // we store the bound function in the local scope so we can use its reference to remove it on the next pass
          overlay.addEventListener("click", this.clue.bind(this));

          // overlay text which is shown when below div is clickable
          const overlayText = document.createElement("p");
          overlayText.className = elementClasses.overlayText;
          overlayText.textContent = "Visa nästa ledtråd";

          overlay.appendChild(overlayText);

          // add to wrapper
          clueToCreate.appendChild(overlay);
          clueToCreate.appendChild(textNode);

          // clue knows which question it belongs to
          clueToCreate.dataset.question = counter;
          clueToCreate.dataset.clue = clueCounter;

          // clue can be clicked to trigger next clue reveal
          clueToCreate.dataset.quizTarget = "clue";
          clueToCreate.dataset.action = "bit-for-bit#clue";

          clueToCreate.className = elementClasses.clue;

          // add button to wrapper
          cluesForCategory.appendChild(clueToCreate);

          this.clues[counter][clueCounter] = clueToCreate;
          clueCounter++;
        });

        this.quizContainer.lastChild.appendChild(cluesForCategory);
      }
    });

    this.nextButton = this.quiz.querySelector("#quiz-next-button");
    this.nextButton.innerText = "Nästa Kategori";

    this.clueButton = this.quiz.querySelector("#clue-button");
    this.clueButton.classList.remove("hidden");

    this.checkProgress();
  }

  checkProgress() {
    if (this.islastCategory()) {
      this.nextButton.innerText = "Se Rätt Svar";
    }

    if (this.isLastClue()) {
      this.clueButton.disabled = true;
    } else {
      this.clueButton.disabled = false;
    }

    // if currentClue is zero it means we have moved to a new category
    if (this.currentClue === 0) {
      this.categories.forEach((el) => el.classList.add("hidden"));

      // show current category
      this.getCurrentCategory().classList.remove("hidden");
      this.getCurrentClues().forEach((el) => {
        this.getClueTextNode(el).classList.add("invisible");
        this.getClueOverlay(el).classList.add("hidden");
      }); // at start we only show top level clue
    }

    const currentClue = this.getCurrentClues()[this.currentClue];
    const overlay = this.getClueOverlay(currentClue);

    // remove interactivity from the current clue
    overlay.classList.add("hidden");

    // unhide newest clue
    this.getClueTextNode(currentClue).classList.remove("invisible");

    if (!this.isLastClue()) {
      const nextClue = this.getCurrentClues()[this.currentClue + 1];

      const overlay = this.getClueOverlay(nextClue);
      overlay.classList.remove("hidden");
    }
  }

  showResult() {
    this.clueButton.disabled = true;
    this.clueButton.classList.add("hidden");
    this.nextButton.disabled = true;
    this.nextButton.classList.add("hidden");

    // TODO: Remove interactivity and reveal all clues
    this.clues.forEach((categoryClues) =>
      categoryClues.forEach((clue) => {
        this.getClueTextNode(clue).classList.remove("invisible");
        this.getClueOverlay(clue).classList.add("hidden");
      })
    );

    this.categories.forEach((category, i) => {
      category.classList.remove("hidden");

      // create elements for dislaying correct answers for questions
      const correctionBox = document.createElement("div");
      correctionBox.className = elementClasses.correctionBox;

      const correctWrapper = document.createElement("p");
      const correctIcon = document.createElement("img");
      const correctLabel = document.createElement("b");
      const correctText = document.createElement("span");

      correctIcon.src = "/public/images/green_check.svg";
      correctIcon.className = elementClasses.icon;
      correctLabel.innerText = "Rätt svar: "; // Note extra space
      correctText.innerText = this.answers[i];

      correctWrapper.appendChild(correctIcon);
      correctWrapper.appendChild(correctLabel);
      correctWrapper.appendChild(correctText);

      correctionBox.appendChild(correctWrapper);

      // append to end of question wrapper, buttons will be hidden later
      category.appendChild(correctionBox);
    });
  }

  // getters and helpers
  getCurrentClues() {
    return this.clues[this.currentCategory];
  }

  getCurrentCategory() {
    return this.categories[this.currentCategory];
  }

  getClueTextNode(clue) {
    return clue.querySelector(".clue-text");
  }

  getClueOverlay(clue) {
    return clue.querySelector(".clue-overlay");
  }

  isLastClue() {
    return this.currentClue + 1 >= this.getCurrentClues().length;
  }

  islastCategory() {
    return !(this.currentCategory + 1 < this.categories.length);
  }

  // actions
  next() {
    if (this.islastCategory()) {
      return this.showResult();
    } else {
      // increment category count
      this.currentCategory++;

      // reset clue as we're now on a new category
      this.currentClue = 0;
    }

    this.checkProgress();
  }

  clue() {
    if (this.isLastClue()) return;

    this.currentClue++;
    this.checkProgress();
  }
}
