import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("Iframe loader connected");
    this.element.onload = this.onloadCallback;
  }

  onloadCallback(e) {
    const resize = e.target.getAttribute("data-resize");
    const currentWidth = e.target.clientWidth;
    if (resize == "skip") {
      // Do nothing
    } else if (resize == "manual") {
      const mobileHeight = e.target.getAttribute("data-mobile-height");
      const desktopHeight = e.target.getAttribute("data-desktop-height");

      if (isNaN(mobileHeight) || isNaN(desktopHeight)) {
        console.log("Set data-mobile-height and data-desktop-height");
      } else {
        if (currentWidth < 600) {
          e.target.height = mobileHeight;
        } else {
          e.target.height = desktopHeight;
        }  
      }
    } else {
      // This is the default behaviour. Will scale the iframe height using the original width and height
      const originalWidth = e.target.getAttribute("data-original-width");
      const originalHeight = e.target.getAttribute("data-original-height");
      let ratio = currentWidth/originalWidth;
      let newHeight = originalHeight*ratio;
      e.target.height = newHeight;
    }
  }
}
