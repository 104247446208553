import { Controller } from "@hotwired/stimulus";
import {
  cachedFetch,
  renditionsToSrcSet,
  srcSetToSourceTag,
} from "../utilities";

export default class extends Controller {
  static targets = ["title", "placeholder"];
  static values = {
    url: String,
    template: String,
  };

  connect() {
    this.load();
  }

  load() {
    cachedFetch(this.urlValue, { mode: "cors" })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (this.titleTarget.innerHTML.trim() == "") {
          this.titleTarget.innerHTML = json.title;
        }

        if (this.hasPlaceholderTarget)
          this.placeholderTarget.outerHTML = json.items
            .map((item) => this.itemTemplate(item))
            .join("");
      });
  }

  itemTemplate(item) {
    if (this.templateValue === "picks") {
      return this.picksTemplate(item);
    } else if (this.templateValue === "play") {
      return this.playTemplate(item);
    }

    return this.defaultTemplate(item);
  }

  defaultTemplate(item) {
    const renditionsWidths = {
      "Landscape-S": "320w",
      "Landscape-M": "665w",
      "Landscape-L": "1000w",
      "Landscape-XL": "1680w",
    };

    const sets = renditionsToSrcSet(
      item._etc.feature_media_renditions,
      "Landscape",
      renditionsWidths
    );

    // `270` is rougly w-64 and `306` is rougly w-72
    const sizes = "(min-width: 850px) 270px, 306px";

    const sources =
      sets && (sets.srcSet.length || sets.webpSet.length)
        ? srcSetToSourceTag(sets.srcSet, "image/png", sizes) +
          srcSetToSourceTag(sets.webpSet, "image/webp", sizes)
        : undefined;

    const fallbackImgSource = sets
      ? sets.fallback.src
      : item.banner_image ?? item._etc.author_byline;

    const fallbackImg = `<img src="${fallbackImgSource}" loading="lazy" class="object-cover w-full aspect-landscape" />`;

    return `
    <div class="w-72 tablet:w-64 flex-shrink-0"
        style="scroll-snap-align: center;">
        <a href="${item.url}">
          <div class="w-full h-full bg-beige">

            <picture>
                ${
                  sources && fallbackImg
                    ? sources + fallbackImg // sources plus fallback image to serve as tag
                    : fallbackImgSource // if we have no sources but we have fallback
                    ? fallbackImg // use only fallback
                    : `<div class="w-full aspect-landscape"></div>`
                }
            </picture>
            <div class="p-4">
              <h1 class="text-black font-medium font-stag-serif text-xl leading-tight pb-1.5">
                  ${item.title}
              </h1>
              <p class="line-clamp-3">
                  ${item.summary}
              </p>
            </div>
          </div>
        </a>
      </div>`;
  }

  picksTemplate(item) {
    // TODO add a `Portrait-M`
    const renditionsWidths = {
      "Portrait-S": "160w",
      // "Portrait-M": "330w",
      "Portrait-L": "500w",
      "Portrait-XL": "840w",
    };

    const sets = renditionsToSrcSet(
      item._etc.feature_media_renditions,
      "Portrait",
      renditionsWidths
    );

    // `270` is rougly w-64 and `306` is rougly w-72
    const sizes = "(min-width: 850px) 270px, 306px";
    const sources =
      sets && (sets.srcSet.length || sets.webpSet.length)
        ? srcSetToSourceTag(sets.srcSet, "image/png", sizes) +
          srcSetToSourceTag(sets.webpSet, "image/webp", sizes)
        : undefined;

    const fallbackImgSource = sets
      ? sets.fallback.src
      : item.banner_image ?? item._etc.author_byline;

    const fallbackImg = `<img src="${fallbackImgSource}" loading="lazy" class="object-cover object-center w-full h-full" />`;

    return `
    <div class="h-108 w-72 md:w-64 flex-shrink-0 relative rounded-lg overflow-hidden"
        style="scroll-snap-align: center;">
        <picture>
            ${
              sources && fallbackImg
                ? sources + fallbackImg
                : fallbackImgSource
                ? fallbackImg
                : `<div class="w-full h-full bg-black"></div>`
            }
        </picture>
        

        <div class="absolute z-0 top-0 w-full h-full bg-gradient-to-t from-black">
          <a href="${item.url}">
            <div class="h-full flex flex-col justify-end">
              <div class="flex flex-col items-center min-h-1/4 px-entry mb-8 space-y-2">
                <h1 class="text-white text-center font-stag-serif font-medium text-xl leading-tight">
                  ${item.title}
                </h1>
              </div>
            </div>
          </a>
        </div>
      </div>`;
  }

  playTemplate(item) {
    // we actually only want to use the `S` size as the render size is very small
    const renditionsWidths = {
      "Landscape-S": "320w",
      "Landscape-M": "665w",
      "Landscape-L": "1000w",
      "Landscape-XL": "1680w",
    };

    const sets = renditionsToSrcSet(
      item._etc.feature_media_renditions,
      "Landscape",
      renditionsWidths
    );

    // when above tablet breakpoint width of carousel entry is 195px
    // below that the slot is 3/4 of the parent element which is almost the entire screen
    const sizes = "(min-width: 850px) 195px, 75vw";

    const sources =
      sets && (sets.srcSet.length || sets.webpSet.length)
        ? srcSetToSourceTag(sets.srcSet, "image/png", sizes) +
          srcSetToSourceTag(sets.webpSet, "image/webp", sizes)
        : undefined;

    const fallbackImgSource = sets
      ? sets.fallback.src
      : item.banner_image ?? item._etc.author_byline;

    const fallbackImg = `<img src="${fallbackImgSource}" loading="lazy" class="object-cover object-center w-full h-full" />`;

    return `
    <div class="w-3/4 tablet:w-52 h-80 flex-shrink-0"
    style="scroll-snap-align: center;">
      <a href="${item.url}">
        <div class="w-full h-full bg-gray-900">

          <div class="h-2/5 relative">
            <picture>
                ${
                  sources && fallbackImg
                    ? sources + fallbackImg
                    : fallbackImgSource
                    ? fallbackImg
                    : `<div class="w-full h-full"></div>`
                }
            </picture>

            <div class="absolute top-0 w-full h-full flex justify-center items-center">
              <div class="flex justify-center items-center opacity-75 w-12 h-12 bg-red rounded-full">
                <img class="w-4 h-4 relative"
                  alt=""
                  style="right: -0.12rem;"
                  loading="lazy"
                  src="/public/images/ic_play.svg" />
                </i>
              </div>
            </div>
          </div>

          <div class="h-3/5">
            <div class="h-full p-4">
              <h1 class="text-white font-bold line-clamp-3">
                ${item.title}
              </h1>
              <div class="pt-2 text-gray-200 line-clamp-3">
                ${item.summary}
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>`;
  }
}
