import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "scrollContainer",
    "indicator",
    "previousButton",
    "nextButton",
    "nextFade",
    "previousFade",
  ];

  connect() {
    this.setInitialState();
    this.updateScrollIndicators();

    this.observer = new MutationObserver(() => {
      this.setInitialState();
      this.updateScrollIndicators();
    });
    this.observer.observe(this.scrollContainerTarget, { childList: true });

    this.scrollContainerTarget.addEventListener(
      "scroll",
      (e) => this.handleScroll(),
      {
        passive: true,
      }
    );
  }

  disconnect() {
    this.observer.disconnect();
    this.scrollContainerTarget.removeEventListener(
      "scroll",
      () => this.handleScroll(),
      {
        passive: true,
      }
    );
  }

  setInitialState() {
    this.maybeTogglePreviousButtonVisibility();
    this.hideButtonsIfNoScroll();
  }

  handleScroll() {
    // We use requestAnimationFrame to make sure that
    // we don't interrupt any browser animations while
    // handling scroll events.
    if (!this.ticking) {
      window.requestAnimationFrame(() => {
        this.updateScrollIndicators();
        this.maybeTogglePreviousButtonVisibility();
        this.ticking = false;
      });

      this.ticking = true;
    }
  }

  next() {
    // Since each slide doesn't cover the whole window, only slide a little bit and depend on the snap functionality
    let scrollX = this.element.scrollWidth * 0.5;
    this.scrollBy(scrollX);
  }

  previous() {
    // Since each slide doesn't cover the whole window, only slide a little bit and depend on the snap functionality
    let scrollX = -1 * this.element.scrollWidth * 0.5;
    this.scrollBy(scrollX);
  }

  scrollBy(amount) {
    this.scrollContainerTarget.scrollBy({
      top: 0,
      left: amount,
      behavior: "smooth",
    });
  }

  hideButtonsIfNoScroll() {
    if (
      this.scrollContainerTarget.scrollWidth <=
      this.scrollContainerTarget.offsetWidth
    ) {
      // scrollContainer element isn't scrollable
      this.hideNextButton();
      this.hidePreviousButton();
    }
  }

  maybeTogglePreviousButtonVisibility() {
    const isScrolledToStart = this.scrollContainerTarget.scrollLeft <= 50;
    const isScrolledToEnd =
      this.scrollContainerTarget.offsetWidth +
        this.scrollContainerTarget.scrollLeft >=
      this.scrollContainerTarget.scrollWidth - 50;

    if (isScrolledToStart) {
      this.hidePreviousButton();
    } else if (this.isPreviousButtonHidden()) {
      this.showPreviousButton();
    }

    if (isScrolledToEnd) {
      this.hideNextButton();
    } else if (this.isNextButtonHidden()) {
      this.showNextButton();
    }
  }

  hidePreviousButton() {
    this.previousButtonTarget.classList.add("hidden");
    this.previousButtonTarget.classList.remove("block");
    this.previousFadeTarget.classList.add("hidden");
  }

  showPreviousButton() {
    this.previousButtonTarget.classList.remove("hidden");
    this.previousButtonTarget.classList.add("block");
    this.previousFadeTarget.classList.remove("hidden");
  }

  hideNextButton() {
    this.nextButtonTarget.classList.add("hidden");
    this.nextButtonTarget.classList.remove("block");
    this.nextFadeTarget.classList.add("hidden");
  }

  showNextButton() {
    this.nextButtonTarget.classList.remove("hidden");
    this.nextButtonTarget.classList.add("block");
    this.nextFadeTarget.classList.remove("hidden");
  }

  isNextButtonHidden() {
    return this.nextButtonTarget.classList.contains("hidden");
  }
  return;
  isPreviousButtonHidden() {
    return this.previousButtonTarget.classList.contains("hidden");
  }

  updateScrollIndicators() {
    const currentScrollPercentage =
      (this.scrollContainerTarget.scrollLeft +
        this.scrollContainerTarget.clientWidth / 2) /
      this.scrollContainerTarget.scrollWidth;

    const numberOfIndicators = this.indicatorTargets.length;

    // Clamp the value with Math.min
    // in case the browser over scrolls
    let newActiveIndicatorIndex = Math.min(
      parseInt(currentScrollPercentage * numberOfIndicators),
      numberOfIndicators - 1
    );

    // We only update the indicator classLists if the active
    // indicator index has changed
    if (this.activeIndicatorIndex != newActiveIndicatorIndex) {
      this.activeIndicatorIndex = newActiveIndicatorIndex;

      this.indicatorTargets.forEach((indicator, i) => {
        if (i === newActiveIndicatorIndex) {
          indicator.classList.remove("text-black");
          indicator.classList.add("text-red");
        } else {
          indicator.classList.remove("text-red");
          indicator.classList.add("text-black");
        }
      });
    }
  }
}
