import PopupController from "./popup_controller.js";

export default class extends PopupController {
  handleButton() {
    const sharingConfig = this.fetchShareData(this.buttonTarget);
    const hasCanShare =
      navigator && navigator.canShare && navigator.canShare(sharingConfig);
    const noCanShare = navigator && !navigator.canShare && navigator.share;

    if (hasCanShare || noCanShare) {
      navigator.share(sharingConfig);
    } else {
      this.popupTarget.classList.contains("hidden")
        ? this.unhide()
        : this.hide();
    }
  }

  // takes an element and gathers the sharing data from that element if it exists
  fetchShareData(element) {
    // sharing configuration contains
    // url: A string representing a URL to be shared.
    // text: A string representing text to be shared.
    // title: A string representing a title to be shared. May be ignored by the target.
    // TO BE IMPLEMENTED! files: An array of File objects representing files to be shared. See below for shareable file types.

    // HTML dataset values are prefixed with `sharing` to avoid potential namespace clashes
    const htmlDataKeyMap = {
      url: "sharingUrl",
      text: "sharingText",
      title: "sharingTitle",
    };

    // reduce over the key|value pairs and pick values from the dataset to store in the new object
    return Object.entries(htmlDataKeyMap).reduce(
      (config, [key, dataProperty]) => {
        const val = element.dataset[dataProperty];

        if (!val) return config;

        var txt = document.createElement("textarea");
        txt.innerHTML = val;
        const decodedVal = txt.value;

        // build dataset
        return decodedVal ? { ...config, [key]: decodedVal } : config;
      },
      {}
    );
  }
}
