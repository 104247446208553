import { Controller } from "@hotwired/stimulus";

export default class AbstractPlayerController extends Controller {
  getTimeInHoursMinutesAndSeconds(time) {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor(time / 60 - hours * 60);
    const seconds = Math.floor(time - hours * 3600 - minutes * 60);
    return {
      seconds: seconds,
      minutes: minutes,
      hours: hours,
    };
  }

  // Will return "5:32 min" or "1 h 5 min"
  getTimeInMoreHumanFriendlyFormat(parts) {
    return parts["hours"] > 0
      ? `${parts["hours"]} h ${parts["minutes"]} min`
      : `${parts["minutes"]}:${parts["seconds"]} min`;
  }

  formatTime(timeInSeconds) {
    const result = new Date(timeInSeconds * 1000).toISOString().substr(11, 8);

    return {
      minutes: result.substr(3, 2),
      seconds: result.substr(6, 2),
    };
  }

  // A very annoying way to get the x pos where the target starts...
  getOffset(initialElement) {
    let offsetTotal = 0;
    let elem = initialElement;
    while (elem) {
      offsetTotal += elem.offsetLeft;
      elem = elem.offsetParent;
    }
    return offsetTotal;
  }

  formatDurationToString(time) {
    const durationParts = this.getTimeInHoursMinutesAndSeconds(time);
    const hoursStr = String(durationParts["hours"]).padStart(2, "0");
    const minutesStr = String(durationParts["minutes"]).padStart(2, "0");
    const secondsStr = String(durationParts["seconds"]).padStart(2, "0");

    // 00:00:00 or 00:00
    return durationParts["hours"] > 0
      ? `${hoursStr}:${minutesStr}:${secondsStr}`
      : `${minutesStr}:${secondsStr}`;
  }

  setMaxDuration(bar, seek, textIndicator, duration) {
    bar.setAttribute("max", duration);
    seek.setAttribute("max", duration);

    textIndicator.textContent = this.formatDurationToString(duration);

    // TODO has to be re-enabled if video controller also inherits this class
    // const durationMoreHumanFriendly =
    //   this.getTimeInMoreHumanFriendlyFormat(durationParts);
    // this.initialplaydurationinfoTarget.textContent = durationMoreHumanFriendly;
  }

  updateProgressBarAndTimeDisplays(time) {
    const currentTime = Math.floor(time);

    if (this.hasProgressbarTarget) this.progressbarTarget.value = currentTime;
    if (this.hasProgressbarseekTarget)
      this.progressbarseekTarget.value = currentTime;
    if (this.hasControllerprogressstringTarget)
      this.controllerprogressstringTarget.textContent =
        this.formatDurationToString(time ?? 0);
  }

  clampNumber = (input, min, max) => Math.min(Math.max(input, min), max);

  moveBarSeeker(
    event,
    progressbarseekTarget,
    progressbarseektooltipTarget,
    playerTarget
  ) {
    // Clamp between 0 and media tag duration
    const skipTo = this.clampNumber(
      Math.round(
        (event.offsetX / event.target.clientWidth) *
          parseInt(event.target.getAttribute("max"), 10)
      ),
      0,
      playerTarget.duration
    );

    progressbarseekTarget.setAttribute("data-seek", skipTo);
    const t = this.formatTime(skipTo);
    progressbarseektooltipTarget.textContent = `${t.minutes}:${t.seconds}`;

    // We don't want the toolbar to go too far or too far right
    const offset = this.getOffset(progressbarseekTarget);
    const halfSize = progressbarseektooltipTarget.clientWidth / 2;
    let leftPixels = event.pageX - offset - halfSize;
    if (leftPixels + halfSize < 0) {
      leftPixels = -halfSize;
    }
    if (leftPixels + halfSize > event.target.clientWidth) {
      leftPixels = event.target.clientWidth - halfSize;
    }

    progressbarseektooltipTarget.style.left = `${leftPixels}px`;
  }
}
