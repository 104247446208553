import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
  ];

  connect() {
    // For app users, we don't want to have a tags with target="_blank"
    // Unfortunately calling ethicalads.wait or ethicalads.load_placement and
    // using the promise does not work, the ad is finished loaded after that.
    // So we simply listen for changes in the div
    let rootNode = document.getRootNode()
    if (rootNode.documentElement && rootNode.documentElement.getAttribute("data-turbo-native-app") == "true") {
      const targetNode = this.element;

      // Options for the observer (which mutations to observe)
      const config = { attributes: false, childList: true, subtree: true };

      // Create an observer instance with a callback function
      const observer = new MutationObserver((mutationList, observer) => {
        targetNode
          .querySelectorAll('a[target="_blank"]')
          .forEach((link) => {
            link.removeAttribute("target");
          });
        // Here we probably could call observer.disconnect();
      });

      // Start observing the target node for configured mutations
      observer.observe(targetNode, config);
    }
  }
}
