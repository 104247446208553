import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    window.addEventListener("scroll", (e) => this.handleScroll(), {
      passive: true,
    });
  }

  disconnect() {
    window.removeEventListener("scroll", () => this.handleScroll(), {
      passive: true,
    });
  }

  handleScroll() {
    // We use requestAnimationFrame to make sure that
    // we don't interrupt any browser animations.
    if (!this.ticking) {
      window.requestAnimationFrame(() => {
        this.maybeAddScrollClass();
        this.ticking = false;
      });

      this.ticking = true;
    }
  }

  maybeAddScrollClass() {
    // We use different values for start and end
    // so that we minimise glitching when scrolled
    // to the exact threshold value.
    const stuckStart = 40;
    const stuckEnd = 30;

    const currentScroll = document.documentElement.scrollTop;

    if (this.isStuck && currentScroll < stuckEnd) {
      this.isStuck = false;
      this.element.classList.remove("stuck");
    } else if (!this.isStuck && currentScroll > stuckStart) {
      this.isStuck = true;
      this.element.classList.add("stuck");
    }
  }
}
