import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["imageContainer"];
  static values = { index: { type: Number, default: -1 } };

  connect() {
    this.handleKeyDown = this.handleKeyDown.bind(this);

    this.isHidden = this.element.classList.contains("hidden");

    this.initImageElements();

    document.addEventListener("keydown", this.handleKeyDown);
  }

  initImageElements() {
    this.handleImageElementClick = this.handleImageElementClick.bind(this);

    const imageElements = window.document.querySelectorAll("figure img");
    this.imageElements = Array.from(imageElements).map(
      (imgElement) => imgElement.parentElement
    );
    this.imageElements.forEach((imgElement) => {
      imgElement.classList.add("cursor-zoom-in");
      imgElement.addEventListener("click", this.handleImageElementClick);
    });
  }

  disconnect() {
    this.imageElements.forEach((imgElement) => {
      imgElement.removeEventListener("click", this.handleImageElementClick);
    });

    document.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown(e) {
    if (!this.isHidden) {
      const { key } = e;

      switch (key) {
        case "Escape":
          this.hide();
          break;
        case "ArrowLeft":
          this.previous();
          break;
        case "ArrowRight":
          this.next();
          break;
        case "ArrowUp":
        case "ArrowDown":
          e.preventDefault();
          break;
        default:
          break;
      }
    }
  }

  handleImageElementClick(e) {
    this.indexValue = this.imageElements.findIndex(
      (imageElement) => imageElement == e.currentTarget
    );
  }

  hide() {
    this.indexValue = -1;

    if (!this.isHiding && !this.isHidden) {
      this.element.addEventListener("transitionend", () => {
        this.isHidden = true;
        this.isHiding = false;
        this.element.classList.add("hidden");
        this.element.classList.remove("opacity-0");
      });

      this.element.classList.add("opacity-0");
      this.isHiding = true;
    }
  }

  next() {
    this.indexValue++;
  }

  previous() {
    if (this.indexValue == 0) {
      this.indexValue = this.imageElements.length - 1;
    } else {
      this.indexValue--;
    }
  }

  indexValueChanged() {
    if (this.imageElements && this.indexValue > -1) {
      this.element.classList.remove("hidden");
      this.isHidden = false;

      this.showCurrentImage();
    }
  }

  showCurrentImage() {
    const imageElement =
      this.imageElements[this.indexValue % this.imageElements.length];

    const clonedElement = imageElement.cloneNode(true);
    clonedElement.classList.remove("cursor-zoom-in");

    clonedElement.classList.add("shadow-md", "flex", "flex-col");

    clonedElement.querySelectorAll("img").forEach((imgElement) => {
      imgElement.classList.add("object-scale-down", "max-h-[75vh]", "bg-white");
      imgElement.removeAttribute("loading");
    });

    clonedElement
      .querySelectorAll("figcaption")
      .forEach((figcaptionElement) =>
        figcaptionElement.classList.add("etc-figcaption", "p-4")
      );

    this.imageContainerTarget.innerHTML = clonedElement.outerHTML;
  }
}
