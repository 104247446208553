import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    showLimitKey: String,
  };

  localStorageKey = "ad-show-sometimes-last-viewed";

  connect() {
    window.addEventListener("scroll", (e) => this.handleScroll(), {
      passive: true,
    });
  }

  disconnect() {
    // We need to run hideElements, otherwise the ad will disappear but the "pushed-down" class
    // will still make the logo and header menu to be pushed down a bit
    this.hideElements();
    window.removeEventListener("scroll", () => this.handleScroll(), {
      passive: true,
    });
  }

  handleScroll() {
    // We use requestAnimationFrame to make sure that
    // we don't interrupt any browser animations.
    if (!this.ticking) {
      window.requestAnimationFrame(() => {
        this.maybeLoadAds();
        this.ticking = false;
      });

      this.ticking = true;
    }
  }

  // This will check if the user has scrolled down a bit on the page
  // When that is done, it will check if the ads should load (i.e. it will check for a localStorage date item and see
  // if it is time to show the ad again)
  maybeLoadAds() {
    const currentScroll = document.documentElement.scrollTop;
    const scrollLimitBeforeShow = 1200;
    if (!this.scrollLimitPassed && currentScroll > scrollLimitBeforeShow) {
      this.scrollLimitPassed = true;
      if (this.shouldLoadAds() && ethicalads) {
        // So we can access "this" from the callback
        let that = this;
        ethicalads.wait.then((_placements) => {
          // Here we need to do a hack becaus of turbolinks. When we have loaded the page it will appear nicely
          // However, when we click on a link and query for .ethical-ad, we won't find anything. In that case
          // we have to add a MutationObserver to listen for changes
          const adElementCheck = that.element.querySelector(".ethical-ad");
          if (adElementCheck) {
            this.handleAdsLoaded();
          } else {
            const targetNode = this.element;
            const config = { attributes: true, childList: true, subtree: true };

            // Callback function to execute when mutations are observed
            const callback = function(mutationsList, observer) {
              // Now we have to stop listening to changes to avoid an infinite loop (since handleAdsLoaded will change even more)
              observer.disconnect();
              that.handleAdsLoaded();
            };
            const observer = new MutationObserver(callback);
            observer.observe(targetNode, config);
          }
        });
      }
    }
  }

  shouldLoadAds() {
    if (this.hasShowLimitKeyValue) {
      const currentDate = new Date();      
      const lastViewedDate = new Date();
      lastViewedDate.setTime(parseInt(localStorage.getItem(this.localStorageKey) ?? 0));
      const nextOKTime = new Date();
      // Limit is set in minutes. setTime takes milliseconds
      nextOKTime.setTime(lastViewedDate.getTime() + parseInt(this.showLimitKeyValue) * 60 * 1000);
      const ok = currentDate > nextOKTime;
      return ok;
    }

    return true;
  }

  handleAdsLoaded() {
    const adElement = this.element.querySelector(".ethical-ad");

    if (adElement) {
      // To be able to show the banner as sticky at the top of the page, we have to
      // make room for it by pushing down the logo and header menu
      const headerMobilelogoElement = document.getElementById("header-mobile-logo");
      if (headerMobilelogoElement) {
        headerMobilelogoElement.classList.add("pushed-down");
      }

      const headerMobileMenuElement = document.getElementById("header-mobile-menu");
      if (headerMobileMenuElement) {
        headerMobileMenuElement.classList.add("pushed-down");
      }

      const headerTabletLogoElement = document.getElementById("header-tablet-logo");
      if (headerTabletLogoElement) {
        headerTabletLogoElement.classList.add("pushed-down");
      }

      const headerTabletMenuElement = document.getElementById("header-tablet-menu");
      if (headerTabletMenuElement) {
        headerTabletMenuElement.classList.add("pushed-down");
      }

      this.element.classList.add("show-me");
    }
  }

  hideElements() {
    this.element.classList.remove("show-me");

    const headerMobilelogoElement = document.getElementById("header-mobile-logo");
    if (headerMobilelogoElement) {
      headerMobilelogoElement.classList.remove("pushed-down");
    }

    const headerMobileMenuElement = document.getElementById("header-mobile-menu");
    if (headerMobileMenuElement) {
      headerMobileMenuElement.classList.remove("pushed-down");
    }

    const headerTabletLogoElement = document.getElementById("header-tablet-logo");
    if (headerTabletLogoElement) {
      headerTabletLogoElement.classList.remove("pushed-down");
    }

    const headerTabletMenuElement = document.getElementById("header-tablet-menu");
    if (headerTabletMenuElement) {
      headerTabletMenuElement.classList.remove("pushed-down");
    }
  }

  hide() {
    this.hideElements();

    const currentDate = new Date();

    localStorage.setItem(
      this.localStorageKey,
      currentDate.getTime()
    );
  }
}
