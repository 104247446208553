import { Controller } from "@hotwired/stimulus";

export default class PopupController extends Controller {
  static targets = ["popup", "button"];

  connect() {}

  handleButton() {
    this.popupTarget.classList.contains("hidden") ? this.unhide() : this.hide();
  }

  hide() {
    this.popupTarget.classList.add("hidden");
  }

  unhide() {
    this.popupTarget.classList.remove("hidden");
  }
}
