import { Controller } from "@hotwired/stimulus";

const elementClasses = {
  question: "flex flex-col quiz-question mb-8",
  figure: "question-image-container mb-6 desktop:mb-10",
  img: "w-full",
  heading: "quiz-question-heading text-xl font-bold text-gray-800",
  questionText: "prose prose-lg font-serif desktop:prose-base",
  answers: "quiz-answers flex flex-col",
  answerButton:
    "quiz-answer w-full p-2 pl-4 mt-2 rounded-[10px] prose prose-lg desktop:prose-base font-semibold text-left bg-beige box-border border border-beige active:bg-black active:text-white active:border-black data-[selected=true]:bg-black data-[selected=true]:text-white data-[selected=true]:border-black",
  correctionBox: "mt-2",
  icon: "inline-block w-6 h-6 mr-2 p-1",
};

const alphabet = "abcdefghijklmnopqrstuvwxyz";

export default class extends Controller {
  static targets = ["question", "nextButton", "answerButton", "info"];

  nextButton;

  questions = [];
  answers = [];
  selections = [];

  currentQuestion = 0;

  connect() {
    this.setupQuiz();
  }

  setupQuiz() {
    // read quiz body data
    const quiz = document.querySelector("#quiz-question-container");
    // clear the innerHTML to remove any duplicates from the user navigating using the history
    quiz.innerHTML = "";

    // assumed format of body
    // <h2>Question text</h2>
    // <figure />
    // <ol>
    //     <li>Answer 1</li>
    //     <li>Answer 2</li>
    //     <li>Answer 3</li>
    // </ol>
    // <p>Extra optional text</p>

    // element where we parse the correct answers but never attach to the document
    const answersDiv = document.createElement("div");
    answersDiv.innerHTML = quiz.dataset.quizAnswers;
    answersDiv.querySelectorAll("li").forEach((item, i) => {
      // get the first character of the li and store that as the correct answer
      this.answers[i] = item.innerText[0];
    });

    // element where we parse quiz elements
    const virtualDiv = document.createElement("div");
    virtualDiv.innerHTML = quiz.dataset.quizBody;

    // infer length of quiz from number of h2's in the html
    const questionLength = virtualDiv.querySelectorAll("h2").length;
    // we initialize on -1 so that on the first question we increment into index0
    let counter = -1;

    virtualDiv.childNodes.forEach((node) => {
      // on new h2 we create a new question
      if (node.nodeName === "H2") {
        // increment counter
        counter++;

        // create div element to wrap the question
        const questionWrapper = document.createElement("div");
        questionWrapper.className = elementClasses.question;

        // save reference to this element
        this.questions[counter] = questionWrapper;

        // create a new p tag for the question text
        const p = document.createElement("p");
        p.innerText = node.innerText;
        p.className = elementClasses.questionText;

        // replace the h2 text with a question counter
        node.innerText = `Fråga ${counter + 1} av ${questionLength}`;
        node.className = elementClasses.heading;
        node.dataset.target = "question";
        node.dataset.question = counter;

        // add text to wrapper
        questionWrapper.appendChild(virtualDiv.removeChild(node));
        questionWrapper.appendChild(p);

        // add question to quiz flow
        quiz.appendChild(questionWrapper);
      } else if (node.nodeName === "FIGURE") {
        // if there is an embedded figure in the html we insert that before the question heading
        const heading = quiz.lastChild.querySelector("h2");

        if (heading) {
          node.className = elementClasses.figure;

          //  insert image before heading for those questions that have images
          quiz.lastChild.insertBefore(node, heading);

          const imgElement = node.querySelector("img");

          if (imgElement) imgElement.className = elementClasses.img;
        }
      } else if (node.nodeName === "OL") {
        // choices come in as ordered lists which we convert into buttons and a wrapper
        const choices = document.createElement("div");
        choices.className = elementClasses.answers;

        // transform each list item into a button with the correct data attributes
        node.querySelectorAll("li").forEach((child, i) => {
          const answerButton = document.createElement("button");
          answerButton.className = elementClasses.answerButton;
          answerButton.innerText = child.innerText;

          // button knows which question it belongs to
          answerButton.dataset.question = counter;
          answerButton.dataset.selected = false;

          // set the corresponding letter
          answerButton.dataset.answer = alphabet[i];

          // controller setup data
          answerButton.dataset.quizTarget = "answerButton";
          answerButton.dataset.action = "quiz#selectAnswer";

          // add button to wrapper
          choices.appendChild(answerButton);
        });

        // add choices to the last question
        quiz.lastChild.appendChild(choices);
        virtualDiv.removeChild(node);
      }
    });

    // setup nextButton
    this.nextButton = document.querySelector("#quiz-next-button");      
    this.nextButton.innerHTML = "Nästa fråga";
    this.checkProgress();

    // hide all but first question
    this.hideQuestions();
    this.showCurrentQuestion();
  }

  selectAnswer(e) {
    // set currentAnswer
    this.selections[this.currentQuestion] = e.target.dataset.answer;

    // set selected=false on all answers in question set
    this.questions[this.currentQuestion]
      .querySelectorAll(".quiz-answer")
      .forEach((a) => (a.dataset.selected = "false"));

    // set selected=true to current target
    e.target.dataset.selected = "true";

    this.checkProgress();
  }

  next() {
    if (this.isLastQuestion) {
      this.checkResult();
    } else {
      this.currentQuestion = this.currentQuestion + 1;

      this.hideQuestions();
      const q = this.showCurrentQuestion(); // returns unhidden question
      q.scrollIntoView({ behavior: "smooth", block: "center" });

      this.checkProgress();
    }
  }

  checkProgress() {
    const hasAnswered = this.selections[this.currentQuestion] !== undefined;
    this.nextButton.disabled = !hasAnswered;

    // change text on button
    if (this.isLastQuestion) {
      this.nextButton.innerHTML = "Se resultat";
    }
  }

  get isLastQuestion() {
    return this.questions.length === this.currentQuestion + 1;
  }

  checkResult() {
    this.nextButton.classList.add("hidden");

    let correctAnswers = 0;

    this.questions.forEach((q) => {
      q.classList.remove("hidden");

      // create elements for dislaying correct answers for questions
      const correctionBox = document.createElement("div");
      correctionBox.className = elementClasses.correctionBox;

      const submittedWrapper = document.createElement("p");
      const submittedIcon = document.createElement("img");
      const submittedLabel = document.createElement("b");
      const submittedText = document.createElement("span");

      const correctWrapper = document.createElement("p");
      const correctIconSpacer = document.createElement("div");
      const correctLabel = document.createElement("b");
      const correctText = document.createElement("span");

      submittedIcon.className = elementClasses.icon;
      submittedIcon.src = "/public/images/red_x.svg";
      correctIconSpacer.className = elementClasses.icon;

      submittedLabel.innerHTML = "<b>Ditt svar: </b>";
      correctLabel.innerHTML = "<b>Rätt svar: </b>";

      submittedWrapper.appendChild(submittedIcon);
      submittedWrapper.appendChild(submittedLabel);
      submittedWrapper.appendChild(submittedText);
      correctWrapper.appendChild(correctIconSpacer);
      correctWrapper.appendChild(correctLabel);
      correctWrapper.appendChild(correctText);

      correctionBox.appendChild(submittedWrapper);
      correctionBox.appendChild(correctWrapper);

      // append to end of question wrapper, buttons will be hidden later
      q.appendChild(correctionBox);

      let correctNum;
      let selectedNum;

      q.querySelectorAll(".quiz-answer").forEach((a, i) => {
        const isCorrectAnswer =
          this.answers[a.dataset.question] === a.dataset.answer;
        const isSelected = a.dataset.selected === "true";

        a.disabled = true;
        a.dataset.selected = "false";

        // store number of correct and selected questions
        // update the text of the boxes
        if (isCorrectAnswer) {
          correctNum = i;
          correctText.innerText = a.innerText;
        }
        if (isSelected) {
          selectedNum = i;
          submittedText.innerText = a.innerText;
        }

        if (isSelected && isCorrectAnswer) {
          correctAnswers++;
          submittedIcon.src = "/public/images/green_check.svg";
        }

        a.classList.add("hidden");
      });

      const heading = q.querySelector(".quiz-question-heading");
      const markedHeading =
        heading.innerHTML + `: ${correctNum === selectedNum ? "Rätt" : "Fel"}`;

      heading.innerHTML = markedHeading;

      q.querySelectorAll(".question-image-container").forEach((pic) =>
        pic.classList.add("hidden")
      );

      document.querySelector(
        "#quiz-header"
      ).innerHTML = `Ditt resultat: ${correctAnswers} av ${this.questions.length}`;
      document.querySelector("#quiz-lead").classList.add("hidden");
    });
  }

  hideQuestions() {
    this.questions.forEach((q) => q.classList.add("hidden"));
  }

  showCurrentQuestion() {
    const q = this.questions[this.currentQuestion];
    q.classList.remove("hidden");

    return q; // return if others want to keep referencing the new question
  }
}
