import { Controller } from "@hotwired/stimulus";

export default class TextToSpeechController extends Controller {
  static targets = [];
  static values = {
    articleId: String,
    spacesBaseUrl: String,
    audioUrl: String,
  };

  static outlets = ["audio-lead"];

  connect() {
    if (this.hasArticleIdValue && this.hasSpacesBaseUrlValue) {
      const url =
        this.spacesBaseUrlValue + "/tts/" + this.articleIdValue + ".mp3";
      try {
        // check cdn if there is a text-to-speech generated audio file corresponding to this article id
        fetch(url, {
          method: "HEAD",
        }).then((res) => {
          if (res.ok) this.audioUrlValue = res.url;
        });
      } catch (e) {
        // empty catch block to avoid error
      }
    }
  }

  audioLeadOutletConnected(outlet) {
    this.audioUrlValueChanged();
  }

  audioUrlValueChanged() {
    if (
      this.audioUrlValue !== "" &&
      this.hasAudioLeadOutlet &&
      // check if target audio player has a url set
      this.audioLeadOutlet.audioUrlValue === ""
    )
      // if outlet lead player has no existing src but this controller does, use this controllers src
      this.audioLeadOutlet.audioUrlValue = this.audioUrlValue;
  }
}
