import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["email", "firstname", "lastname", "invalid", "registerbutton", "registerprocessing", "registerok", "registerexisting"];
  ajaxRequest = new XMLHttpRequest();

  connect() {
    // Enable "Enter" as a way to submmit the email
    const that = this;
    this.emailTarget.addEventListener("keypress", function(event) {
      if (event.key === "Enter") {
        that.registerbuttonTarget.click();
      }
    });
  }

  register() {
    const emailInput = this.emailTarget;
    const email = emailInput.value;

    const firstnameInput = this.firstnameTarget;
    const firstname = firstnameInput.value;

    const lastnameInput = this.lastnameTarget;
    const lastname = lastnameInput.value;

    if (email == "") {
      return;
    }
    this.registerokTarget.classList.add("hide");
    this.registerexistingTarget.classList.add("hide");
    
    const invalidEmailDiv = this.invalidTarget;
    invalidEmailDiv.classList.add("hide");
    if (!emailInput.checkValidity()) {
      invalidEmailDiv.classList.remove("hide");
      return;
    }
    emailInput["disabled"] = true;
    this.registerbuttonTarget.classList.toggle("button-clicked");
    this.registerprocessingTarget.classList.toggle("hide");

    this.ajaxRequest.open("POST", "/tulo/register");

    this.ajaxRequest.setRequestHeader(
      "Content-type",
      "application/x-www-form-urlencoded"
    );
    this.ajaxRequest.setRequestHeader("Accept", "application/json");
    
    const that = this;

    this.ajaxRequest.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 200) {
        that.registerprocessingTarget.classList.toggle("hide");
        that.registerbuttonTarget.classList.toggle("button-clicked");
        emailInput["disabled"] = false;
        const response = JSON.parse(this.responseText);
        switch (response.status) {
          case 200:
            that.registerokTarget.classList.remove("hide");
            break;
          case 409:
            that.registerexistingTarget.classList.remove("hide");
            break;
          default:
            invalidEmailDiv.classList.remove("hide");
            break;
        }
      }
    };
    this.ajaxRequest.send(`email=${email}&first_name=${firstname}&last_name=${lastname}`);
  }
}
