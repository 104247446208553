import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["shareButton"];

  connect() {
    // select buttons that shares
    const shareButtons = document.querySelectorAll(".native-share-button");

    // if we do not have access to the share functionallity, hide all
    if (!navigator.share)
      shareButtons.forEach((btn) => btn.classList.add("hidden"));
    else {
      // check data validity of each share button and hide if it does not pass check
      shareButtons.forEach((button) => {
        const data = this.fetchShareData(button);

        if (!navigator.canShare(data)) button.classList.add("hidden");
      });
    }
  }

  share(e) {
    try {
      // find closest element with class that indicated correct dataset
      const btn = e.target.closest(".native-share-button");

      const sharingConfig = this.fetchShareData(btn);

      if (navigator.canShare(sharingConfig)) navigator.share(sharingConfig);

      // TODO add plausible calls?
    } catch (error) {
      // TODO: Do something with sharing error
    }
  }

  // takes an element and gathers the sharing data from that element if it exists
  fetchShareData(element) {
    // sharing configuration contains
    // url: A string representing a URL to be shared.
    // text: A string representing text to be shared.
    // title: A string representing a title to be shared. May be ignored by the target.
    // TO BE IMPLEMENTED! files: An array of File objects representing files to be shared. See below for shareable file types.

    // HTML dataset values are prefixed with `sharing` to avoid potential namespace clashes
    const htmlDataKeyMap = {
      url: "sharingUrl",
      text: "sharingText",
      title: "sharingTitle",
    };

    // reduce over the key|value pairs and pick values from the dataset to store in the new object
    return Object.entries(htmlDataKeyMap).reduce(
      (config, [key, dataProperty]) => {
        const val = element.dataset[dataProperty];

        // build dataset
        return val ? { ...config, [key]: val } : config;
      },
      {}
    );
  }
}
