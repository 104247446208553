import { Controller } from "@hotwired/stimulus";
import { debounce } from "../utilities.js";

/**
 * This Text balance controller attempts to balance headings
 * and even out the number of words per line.
 *
 * It does that by shrinking the width of an element until its content
 * wraps onto an additional line. And then it shrinks it back one step
 * so that it's just wide enough to fit the content on the original
 * number of lines.
 *
 * Algorithm from https://www.ctrl.blog/entry/text-wrap-balance.html
 */
export default class extends Controller {
  connect() {
    this.debouncedBalanceText = debounce(() => {
      this.balanceText();
    }, 50);

    if (window.ResizeObserver) {
      this.resizeObserver = new ResizeObserver((_entries) => {
        this.debouncedBalanceText();
      });

      this.resizeObserver.observe(this.element.parentElement);
    }

    document.fonts.ready.then(() => {
      requestAnimationFrame(() => {
        this.balanceText();
      });
    });

    this.balanceText();
  }

  disconnect() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  balanceText() {
    if (this.isMultipleLines()) {
      this.element.style.maxWidth = null;
      const width = this.element.parentElement.clientWidth;
      const bottomRange = Math.max(100, parseInt(width / 2));
      this.squeezeContainer(this.element.clientHeight, bottomRange, width);
    }
  }

  squeezeContainer(originalHeight, bottomRange, topRange) {
    if (bottomRange + 4 >= topRange) {
      this.element.style.maxWidth = Math.ceil(topRange) + "px";
      return;
    }

    const mid = (bottomRange + topRange) / 2;
    this.element.style.maxWidth = mid + "px";

    if (
      this.element.clientHeight > originalHeight ||
      this.element.scrollWidth > this.element.clientWidth
    ) {
      // We've squoze too far and headline has spilled
      // onto an additional line. Or, there is a long word
      // that is overflowing the container.
      this.squeezeContainer(originalHeight, mid, topRange);
    } else {
      // headline has not wrapped to another line; keep squeezing!
      this.squeezeContainer(originalHeight, bottomRange, mid);
    }
  }

  isMultipleLines() {
    const elementStyles = window.getComputedStyle(this.element);
    const elementLineHeight = parseInt(elementStyles["line-height"], 10);
    const elementHeight = parseInt(elementStyles["height"], 10);
    return elementLineHeight < elementHeight;
  }
}
