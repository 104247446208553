import { Controller } from "@hotwired/stimulus";

const LS_CORAL_KEY = "coralJWT";
const ADMIN = "ADMIN";
const MODERATOR = "MODERATOR";
const STAFF = "STAFF";
const MEMBER = "MEMBER";
const COMMENTER = "COMMENTER";
const STAFF_ROLES = [ADMIN, MODERATOR, STAFF, MEMBER];

class JWTExpiredError extends Error {}
class JWTMissingError extends Error {}
class JWTMalformedError extends Error {}

export default class extends Controller {
  static values = {
    storyId: String,
    storyUrl: String,
    coralBaseUrl: String,
    className: String,
    loginHref: String,
    className: String,
    type: String,
  };
  static targets = [
    "script",
    "uploadLink",
    "livestream",
    "liveStreamButtonText",
  ];

  connect() {
    if (document.querySelector("html").dataset.loggedIn !== "true")
      this.clearJWT();

    this.getCoralUser()
      .then(([jwt, decodedJWT]) => {
        this.loadCoral(jwt, decodedJWT);

        if (this.isStaff(decodedJWT)) this.showUploadLink();
        else if (this.typeValue === "livestream") this.showLiveStream();
      })
      .catch((err) => {
        // TODO log to sentry?
        // load coral without jwt which will prompt user to log in
        this.loadCoral();
      });
  }

  async getCoralUser() {
    const coralJWT = localStorage.getItem(LS_CORAL_KEY);

    if (coralJWT) {
      try {
        const decodedJWT = this.decodeJWT(coralJWT);

        // check jwt expire
        const now = new Date();
        // token expire format will be Unix time in seconds
        const exp = new Date(decodedJWT.exp * 1000);

        // if not expried, return and exit early
        if (exp > now) return [coralJWT, decodedJWT];
        else throw new JWTExpiredError();
      } catch (error) {
        // fall through and fetch new jwt
        this.clearJWT();
      }
    }

    // get coral jwt token
    return fetch("/coral/login")
      .then((res) => res.text())
      .then((jwt) => {
        localStorage.setItem(LS_CORAL_KEY, jwt);
        return [jwt, this.decodeJWT(jwt)];
      })
      .catch((err) => {
        throw new JWTMissingError();
      });
  }

  clearJWT() {
    localStorage.removeItem(LS_CORAL_KEY);
  }

  decodeJWT(token) {
    try {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    } catch (error) {
      // TODO log to sentry
      this.clearJWT();
      throw new JWTMalformedError();
    }
  }

  loadCoral(jwt, decodedJWT) {
    let staffStatus = "logged-out";
    try {
      switch (decodedJWT.user.role) {
        case ADMIN:
        case MEMBER:
        case MODERATOR:
        case STAFF:
          staffStatus = "staff";
          break;
        case COMMENTER:
          staffStatus = "not-staff";
          break;
        default:
          staffStatus = "logged-out";
          break;
      }
    } catch (e) {}

    const containerClassName = `${this.classNameValue}-${this.typeValue} ${staffStatus}`;

    const config = {
      containerClassName,
      id: "coral_thread",
      autoRender: false,
      rootURL: this.coralBaseUrlValue,
      accessToken: jwt,
      storyID: this.storyIdValue,
      storyURL: this.storyUrlValue,
      events: (events) => {
        // these have to be arrow functions for `this` to work
        events.on("loginPrompt", () => {
          // Redirect user to a login page.
          location.href = this.loginHrefValue;
        });
      },
    };

    this.scriptTarget.src = this.coralBaseUrlValue + "/assets/js/embed.js";
    this.scriptTarget.async = false;
    this.scriptTarget.defer = true;
    this.scriptTarget.onload = function () {
      const streamEmbed = Coral.createStreamEmbed(config);
      // Render directly because auto render does not render early enough
      streamEmbed.render();
    };
  }

  // TODO this uses query selectors as we don't have access to adding target properties on elements added by the coral embed script
  toggleCoralInputForm() {
    const coralInputFormElement = document
      .querySelector("#coral_thread div")
      .shadowRoot.querySelector(
        "#post-comment-form .coral.coral-createComment"
      );

    if (
      coralInputFormElement &&
      window
        .getComputedStyle(coralInputFormElement)
        .getPropertyValue("display") == "none"
    ) {
      coralInputFormElement.style.display = "block";

      this.liveStreamButtonTextTarget.innerHTML = "Stäng";
    } else if (coralInputFormElement) {
      coralInputFormElement.style.display = "none";

      this.liveStreamButtonTextTarget.innerHTML = "Skriv inlägg";
    }
  }

  // show methods only go one-way since they are evaluated on
  // every page-load and are largely static on user or page type
  showUploadLink() {
    this.uploadLinkTarget.classList.replace("hidden", "flex");
  }
  showLiveStream() {
    this.livestreamTarget.classList.replace("hidden", "flex");
  }

  isStaff(decodedJWT) {
    try {
      const role = decodedJWT.user.role;
      return STAFF_ROLES.includes(role);
    } catch (error) {
      // catch and return false in case we can't access the role property
      return false;
    }
  }
}
