import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const allowedEmbedsCookie = this.getCookie("allow-embeds");
    const allowedDomains = allowedEmbedsCookie.split('|');
    const filteredAllowedDomains = allowedDomains.filter(e => e);
    filteredAllowedDomains.forEach((domain) => {
      const listItemElement = document.createElement("li");
      listItemElement.innerHTML = '<span>'+domain+'</span><button class="remove-domain" data-action="embeds-settings#deleteDomain" data-domain="'+domain+'">Ta bort</button>';
      this.element.appendChild(listItemElement);
    });
  }

  setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;SameSite=Lax";
  }
  
  getCookie(cname) {
    const name = cname + "=";
    const ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  deleteDomain(event) {
    const domain = event.target.getAttribute("data-domain");
    const allowedEmbedsCookie = this.getCookie("allow-embeds");
    const allowedDomains = allowedEmbedsCookie.split('|');
    // Remove the domain from the list
    const filteredAllowedDomains = allowedDomains.filter(e => e != domain);

    const newCookieValue = filteredAllowedDomains.join("|");
    this.setCookie("allow-embeds", newCookieValue);

    // Now remove the li element from the ul list
    event.target.parentNode.parentNode.removeChild(event.target.parentElement);

    event.preventDefault();
  }
}
