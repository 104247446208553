import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["menu"];

  connect() {
    let options = {
      threshold: 0.4,
    };

    this.isHidden = this.menuTarget.classList.contains("hidden");

    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          this.hideMenu();
        }
      });
    }, options);
    this.observer.observe(this.menuTarget);
  }

  toggle() {
    if (this.isHidden) {
      this.showMenu();
    } else {
      this.hideMenu();
    }
  }

  showMenu() {
    this.isHidden = false;
    this.menuTarget.classList.remove("hidden");
  }

  hideMenu() {
    if (!this.isHiding && !this.isHidden) {
      this.menuTarget.addEventListener("transitionend", () => {
        this.isHidden = true;
        this.isHiding = false;
        this.menuTarget.classList.add("hidden");
        this.menuTarget.classList.remove("opacity-0");
      });

      this.menuTarget.classList.add("opacity-0");
      this.isHiding = true;
    }
  }

  disconnect() {
    this.observer.disconnect();
  }
}
