import { Controller } from "@hotwired/stimulus";

// First time a div is seen in the viewport, send a plausible event
export default class extends Controller {
  static values = { goal: String, props: Object };

  connect() {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting && !this.hasBeenSeen) {
          const defaultProps = {};

          const plausibleEventProps = {
            ...defaultProps,
            ...this.propsValue,
          };

          plausible(this.goalValue, { props: plausibleEventProps });
          this.hasBeenSeen = true;
        }
      });
    }, {});

    observer.observe(this.element);
  }
}
