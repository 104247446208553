import "../css/styles.css";
import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

window.Stimulus = Application.start();
const context = require.context("./controllers", true, /\.js$/);
Stimulus.load(definitionsFromContext(context));

import * as Turbo from "@hotwired/turbo";
window.Turbo = Turbo;


// We need to call ethicalads.load_placements for each navigation
// which will trigger a turbo:render event. turbo:render won't be called
// on the initial page load as opposed to turbo:load
document.documentElement.addEventListener("turbo:render", () => {
  console.debug("turbo:render");
  // `load_placements` is same as `load` but we get access to the underlying promise
  ethicalads
    .load_placements(true)
    .then(() => {
      console.debug("turbo:load ethicalads load_placements then");
    }).catch(() => {
      console.debug("turbo:load ethicalads load_placements catch");
    });

  console.debug("turbo:render after ethicalads.load");
});

// We need to do a call to plausible for each page navigation
document.documentElement.addEventListener("turbo:load", () => {
  console.log("turbo:load");
  const loggedInInfo = document.getElementById('logged-in-info');
  const loggedIn = loggedInInfo?.dataset.loggedIn ?? false;
  const device = loggedInInfo?.dataset.device ?? "webb";
  const subscriptions = loggedInInfo?.dataset.subscriptions ?? "";
  const subscriptionsArray = subscriptions.split(',');
  let props = {
    "logged_in": loggedIn,
    "device": device,
    "D-ETC": subscriptionsArray.indexOf("D-ETC") != -1,
    "H-ETC": subscriptionsArray.indexOf("H-ETC") != -1,
    "KLOKA H": subscriptionsArray.indexOf("KLOKA H") != -1,
    "PLAY": subscriptionsArray.indexOf("PLAY") != -1,
  };
  plausible("pageview", {props: props});
});
