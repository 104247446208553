import AbstractPlayerController from "./abstract_player_controller";

export default class extends AbstractPlayerController {
  static targets = [
    "fixedaudioplayer",
    "leadaudioplayer",
    "audiotag",
    "playbutton",
    "playicon",
    "pauseicon",
    "controllers",
    "controllerprogressstring",
    "controllerdurationstring",
    "progressbar",
    "progressbarseek",
    "progressbarseektooltip",
    "headline",
  ];

  static values = {
    isMain: Boolean,
    audioQueue: Array,
    audioUrl: String,
    audioHeadline: String,
  };

  static outlets = ["audio-player"];

  connect() {
    // When we can play, we want to see the duration
    this.audiotagTarget.addEventListener(
      "canplay",
      () =>
        this.setMaxDuration(
          this.progressbarTarget,
          this.progressbarseekTarget,
          this.controllerdurationstringTarget,
          this.audiotagTarget.duration
        ),
      false
    );

    // If we missed the canplay event and duration is already available
    if (this.audiotagTarget.duration) {
      this.setMaxDuration(
        this.progressbarTarget,
        this.progressbarseekTarget,
        this.controllerdurationstringTarget,
        this.audiotagTarget.duration
      );
    }

    this.progressbarseekTarget.addEventListener("mousemove", (event) =>
      this.moveBarSeeker(
        event,
        this.progressbarseekTarget,
        this.progressbarseektooltipTarget,
        this.audiotagTarget
      )
    );

    this.progressbarseekTarget.addEventListener("mouseenter", (event) => {
      this.progressbarseektooltipTarget.style.display = "flex";
    });

    this.progressbarseekTarget.addEventListener("mouseleave", (event) => {
      this.progressbarseektooltipTarget.style.display = "none";
    });

    this.audiotagTarget.addEventListener("timeupdate", (e) => {
      this.updateProgressBarAndTimeDisplays(this.audiotagTarget.currentTime);
      this.updatePlayTimeForQueue();
    });

    this.audiotagTarget.addEventListener(
      "play",
      () => {
        this.pauseiconTarget.classList.remove("hidden");
        this.playiconTarget.classList.add("hidden");
      },
      false
    );

    this.audiotagTarget.addEventListener(
      "pause",
      () => {
        this.playiconTarget.classList.remove("hidden");
        this.pauseiconTarget.classList.add("hidden");
      },
      false
    );

    // Progress bar
    this.progressbarseekTarget.addEventListener("input", (event) => {
      const skipTo = this.clampNumber(
        event.target.dataset.seek
          ? event.target.dataset.seek
          : event.target.value,
        0,
        this.audiotagTarget.duration
      );

      this.progressbarTarget.value = skipTo;
      this.progressbarseekTarget.value = skipTo;
      this.audiotagTarget.currentTime = skipTo;
    });

    // Space bar pause
    this.audiotagTarget.addEventListener("keydown", (e) => {
      if (e.which == 32) {
        e.preventDefault();
        e.stopImmediatePropagation();
        this.togglePlayPause();
        return false;
      }
    });

    this.audioQueueValue.length
      ? this.showFixedController()
      : this.hideFixedController();
  }

  // simple check to return true if audio is currently playing
  isCurrentlyPlayingAudio() {
    return !(
      this.audiotagTarget.currentTime == 0 ||
      this.audiotagTarget.paused ||
      this.audiotagTarget.ended
    );
  }

  playNext() {
    try {
      // ensure that we can get the first object from the queue
      const queue = this.audioQueueValue;
      const firstQueued = queue[0];
      if (!firstQueued) {
        // TODO
        this.hideFixedController();
        throw new Error("Attempted to play without anything in queue");
      }

      this.setMaxDuration(
        this.progressbarTarget,
        this.progressbarseekTarget,
        this.controllerdurationstringTarget,
        this.audiotagTarget.duration
      );

      this.audiotagTarget.src = firstQueued.url;
      this.audiotagTarget.currentTime = firstQueued.timeElapsed ?? 0;
      this.headlineTarget.textContent = firstQueued.headline;

      this.audiotagTarget.play();
    } catch (e) {
      // TODO add sentry function here
      console.error(e);
    }
  }

  stopPlayingAndClose() {
    this.audiotagTarget.pause();
    // reset the queue
    this.audioQueueValue = [];
    // reset audiotag

    // For some reason we need to add a timeount around src tag removal or else pause event does not fire
    setTimeout(() => {
      this.audiotagTarget.src = "";
    }, 10);
    this.audiotagTarget.currentTime = 0;
    this.hideFixedController();
  }

  hideFixedController() {
    if (!this.element.classList.contains("hidden")) {
      this.hideTimeout = setTimeout(
        () => this.element.classList.add("hidden"),
        150 /* magic number from tailwind transition */
      );
    }
    this.element.classList.replace("bottom-0", "-bottom-40");
  }

  showFixedController() {
    if (this.hideTimeout) clearTimeout(this.hideTimeout);
    if (this.element.classList.contains("hidden"))
      this.element.classList.remove("hidden");

    this.element.classList.replace("-bottom-40", "bottom-0");
  }

  togglePlayPause() {
    this.audiotagTarget.paused || this.audiotagTarget.ended
      ? this.audiotagTarget.play()
      : this.audiotagTarget.pause();
  }

  updatePlayTimeForQueue() {
    try {
      if (!this.audiotagTarget.src)
        throw new Error("Media tag source not found");
      if (!this.hasAudioQueueValue) {
        throw new Error("Missing audio queue");
      }

      const queue = this.audioQueueValue;
      const timeElapsed = this.audiotagTarget.currentTime;
      const currentSource = this.audiotagTarget.src;

      // TODO figure out if this is too expensive to run so much
      // TODO HEATMAP
      this.audioQueueValue = queue.map((el) =>
        el.url === currentSource ? { ...el, timeElapsed } : el
      );
    } catch (error) {
      // console.error(error);
    }
  }
}
