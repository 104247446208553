import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "elementtofade",
    "elementtocheck"
  ];
  connect() {
    const overlayElement = this.elementtofadeTarget;

    // This will trigger 300 px before there is no intersection between
    // the element and the browser viewport
    const options = {
      root: null,
      rootMargin: "-300px 0px 0px 0px",
      threshold: 0,
    };
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          overlayElement.style.opacity = "50%";
        } else {
          overlayElement.style.opacity = "100";
        }
      });
    }, options);

    observer.observe(this.elementtocheckTarget);
  }
}