import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static classes = [
    "loadedWithAds",
    "loadedWithoutAds",
    "notLoaded",
    "removeWhenLoaded",
  ];
  static values = {
    adNameKey: String,
    freqInMinutes: Number,
    deferedAdNameKey: String,
    deferedTimeInSeconds: Number,
    waitBefore: Number, //wait to show in seconds
  };

  connect() {
    const targetNode = this.element;

    // Options for the observer (which mutations to observe)
    const config = { attributes: false, childList: true, subtree: true };
    // Create an observer instance with a callback function
    const observer = new MutationObserver((mutationList, observer) => {
      // When we have an element with the ethical-ad class, it means we have successfully loaded
      // it from ethical ads
      if (targetNode.querySelectorAll('.ethical-ad').length > 0) {
        if (this.shouldLoadAds()) {
          this.handleAdsLoaded();
        }
      }
      // Here we probably could call observer.disconnect();
    });

    // Start observing the target node for configured mutations
    observer.observe(targetNode, config);
  }

  shouldLoadAds() {
    return this.hasAdNameKeyValue
      ? this.adTimingCheck(this.adNameKeyValue, this.freqInMinutesValue)
      : true;
  }

  // simple date checker to see if given ad was placed within given timeout
  // returns false if specified ad *was* placed within window
  adTimingCheck(adNameKey, timeoutInMinutes) {
    // convert minutes to milliseconds
    const timeoutInMS = timeoutInMinutes * 60000;

    const currentDate = new Date();
    const lastViewedDate = new Date(localStorage.getItem(adNameKey));

    // if we find a time in the storage, we check if the timeout has elapsed
    return currentDate.getTime() - lastViewedDate.getTime() > timeoutInMS;
  }

  handleAdsLoaded() {
    setTimeout(() => {
      const noDeferal =
        this.hasDeferedAdNameKeyValue && this.hasDeferedTimeInSecondsValue
          ? this.adTimingCheck(
              this.deferedAdNameKeyValue,
              this.deferedTimeInSecondsValue / 60
            )
          : true;

      if (noDeferal) {
        if (this.hasLoadedWithAdsClass) {
          this.element.classList.add(...this.loadedWithAdsClasses);
        }

        if (this.hasRemoveWhenLoadedClass) {
          this.element.classList.remove(...this.removeWhenLoadedClasses);
        }

        if (this.isVisible()) {
          this.updateLastViewedDate();
        }
      } else {
        if (this.hasLoadedWithoutAdsClass) {
          this.element.classList.add(...this.loadedWithoutAdsClasses);
        }
      }
    }, this.waitBeforeValue * 1000);
  }

  updateLastViewedDate() {
    if (this.hasAdNameKeyValue) {
      const currentDate = new Date();
      localStorage.setItem(this.adNameKeyValue, currentDate.toUTCString());
    }
  }

  // popup and takeover ads implement a close button
  collapse() {
    this.element.classList.add("hidden");
  }

  isVisible() {
    return this.element.offsetWidth > 0 && this.element.offsetHeight > 0;
  }
}
