import { Controller } from "@hotwired/stimulus";
import {
  cachedFetch,
  renditionsToSrcSet,
  srcSetToSourceTag,
} from "../utilities";

const backgroundColors = {
  klokah: "#FFF3E7",
};
const vignetteClasses = {
  klokah: "text-kloka-hem font-semibold",
};

export default class extends Controller {
  static targets = ["container"];

  static values = {
    url: String,
    template: String,
    theme: String,
    index: Number,
    publishedWindowInDays: Number,
  };

  connect() {
    this.load();
  }

  load() {
    cachedFetch(this.urlValue, { mode: "cors" })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (Array.isArray(json.items)) {
          const index = this.hasIndexValue ? this.indexValue : 0;
          const article = json.items[index];

          const date = new Date();
          const seenFrom =
            this.hasPublishedWindowInDaysValue &&
            this.publishedWindowInDaysValue > 0
              ? date.setDate(date.getDate() - this.publishedWindowInDaysValue)
              : 0;
          const articlePublishedAt = new Date(article.publishedAt);
          const inDateRange = this.hasPublishedWindowInDaysValue
            ? articlePublishedAt.getTime() > seenFrom
            : true;

          if (article && inDateRange) {
            this.containerTarget.outerHTML = this.itemTemplate(article);
          }
        }
      });
  }

  itemTemplate(item) {
    switch (this.templateValue) {
      case "premium":
        return this.premiumTemplate(item);
      default:
        return this.defaultTemplate(item);
    }
  }

  defaultTemplate(item) {
    const picture =
      item._etc && item._etc.feature_media_renditions
        ? this.entryImage(item._etc.feature_media_renditions)
        : "";

    const vignette = item._etc ? item._etc.paperName : undefined;

    return `
        <section 
            class="relative" 
            style="background-color: ${
              this.hasThemeValue ? backgroundColors[this.themeValue] : "#fff"
            }"
        >
            <div>
                <a href="${item.url}">
                    ${picture}
                    <div class="px-entry py-3.5">
                        <h2 class="entry-heading">
                            ${item.title}
                        </h2>
                        <div class="pt-2">
                        ${
                          vignette
                            ? `<span class="text-sm uppercase ${
                                vignetteClasses[this.themeValue]
                                  ? `${vignetteClasses[this.themeValue]}`
                                  : "font-extrabold"
                              }">
                                    ${vignette}.
                                </span> ${item.summary}`
                            : item.summary
                        }
                        </div>
                    </div>
                </a>
            </div>
        </section>
    `;
  }

  premiumTemplate(item) {
    const picture =
      item._etc && item._etc.feature_media_renditions
        ? this.entryImage(item._etc.feature_media_renditions)
        : "";

    const vignette = item._etc ? item._etc.paperName : undefined;

    return `
        <a href="${item.url}">
            <div style="background-color: ${
              this.hasThemeValue ? backgroundColors[this.themeValue] : "#fff"
            }"
            >
                ${picture}
                <div class="px-entry py-4">
                    ${
                      vignette
                        ? `<div class="font-extrabold text-sm ${
                            vignetteClasses[this.themeValue]
                          }">
                            ${vignette}
                        </div>`
                        : ""
                    }
                    <h1 class="premium-heading">
                        ${item.title}
                    </h1>
                </div>
            </div>
        </a>
    `;
  }

  entryImage(renditions) {
    const sets = renditionsToSrcSet(renditions);
    if (!sets) return "";

    const sources =
      sets && (sets.srcSet.length || sets.webpSet.length)
        ? srcSetToSourceTag(sets.srcSet, "image/png") +
          srcSetToSourceTag(sets.webpSet, "image/webp")
        : undefined;

    if (sources || sets.fallback.src) {
      let sourceTags = sources ? sources : "";
      const imgClasses = "w-full h-auto";
      return `
        <picture class="relative w-full">
            ${sourceTags}
            ${
              sources
                ? `
                <img class="${imgClasses}"
                    src="${sets.fallback.src}"
                    alt=""
                />`
                : `<img class="${imgClasses}"
                    src="${sets.fallback.src}"
                    width="${sets.fallback.width}"
                    heigth="${sets.fallback.heigth}"
                    alt=""
                />`
            }
        </picture>`;
    }
  }
}
