import AbstractPlayerController from "./abstract_player_controller";

export default class extends AbstractPlayerController {
  static targets = [
    "fixedaudioplayer",
    "leadaudioplayer",
    "audiotag",
    "playbutton",
    "playicon",
    "pauseicon",
    "controllers",
    "controllerprogressstring",
    "controllerdurationstring",
    "progressbar",
    "progressbarseek",
    "progressbarseektooltip",
    "headline",
  ];

  static values = {
    audioUrl: String,
    audioHeadline: String,
    hideMainPlayer: Boolean,
  };

  static outlets = ["audio-player"];

  connect() {
    if (this.audioUrlValue !== "") {
      this.element.classList.remove("hidden");
    }

    // When we can play, we want to see the duration
    if (this.hasProgressbarTarget) {
      this.audiotagTarget.addEventListener(
        "canplay",
        () => {
          this.setMaxDuration(
            this.progressbarTarget,
            this.progressbarseekTarget,
            this.controllerdurationstringTarget,
            this.audiotagTarget.duration
          );
          this.subscribeUI(this.audioPlayerOutlet.audiotagTarget);
        },
        false
      );

      // If we missed the canplay event and duration is already available
      if (this.audiotagTarget.duration) {
        this.setMaxDuration(
          this.progressbarTarget,
          this.progressbarseekTarget,
          this.controllerdurationstringTarget,
          this.audiotagTarget.duration
        );
      }
    }

    if (this.hasProgressbarTarget) {
      this.progressbarseekTarget.addEventListener("mousemove", (event) =>
        this.moveBarSeeker(
          event,
          this.progressbarseekTarget,
          this.progressbarseektooltipTarget,
          this.audiotagTarget
        )
      );

      this.progressbarseekTarget.addEventListener("mouseenter", (event) => {
        this.progressbarseektooltipTarget.style.display = "flex";
      });

      this.progressbarseekTarget.addEventListener("mouseleave", (event) => {
        this.progressbarseektooltipTarget.style.display = "none";
      });
    }

    this.subscribeUI(this.audioPlayerOutlet.audiotagTarget);
  }

  audioUrlValueChanged() {
    if (this.audioUrlValue !== "") {
      this.element.classList.remove("hidden");
      this.audiotagTarget.src = this.audioUrlValue;
    } else {
      this.element.classList.add("hidden");
    }
  }

  // Called when content players find the fixed player
  // Used to resubscribe to playtime when re-entering an article with audio content
  audioPlayerOutletConnected(outlet) {
    this.subscribeUI(this.audioPlayerOutlet.audiotagTarget);

    if (!this.isMyUrlInMainPlayer() || !this.hideMainPlayerValue) return;
    if (this.checkSelfVisibility()) {
      this.audioPlayerOutlet.hideFixedController();
    } else {
      this.audioPlayerOutlet.showFixedController();
    }
  }

  audioPlayerOutletDisconnected() {
    if (this.isMyUrlInMainPlayer()) {
      this.audioPlayerOutlet.showFixedController();
    }
  }

  subscribeUI(mediaTag) {
    // Setup event listeners
    mediaTag.addEventListener("timeupdate", (e) => {
      if (this.isMyUrlInMainPlayer()) {
        this.updateProgressBarAndTimeDisplays(mediaTag.currentTime);
      }
    });

    if (this.hasPauseiconTarget && this.hasPlayiconTarget) {
      mediaTag.addEventListener(
        "play",
        () => {
          this.pauseiconTarget.classList.remove("hidden");
          this.playiconTarget.classList.add("hidden");
        },
        false
      );

      mediaTag.addEventListener(
        "pause",
        () => {
          this.playiconTarget.classList.remove("hidden");
          this.pauseiconTarget.classList.add("hidden");
        },
        false
      );
    }

    if (this.hasProgressbarTarget) {
      // Progress bar
      this.progressbarseekTarget.addEventListener("input", (event) => {
        const skipTo = this.clampNumber(
          event.target.dataset.seek
            ? event.target.dataset.seek
            : event.target.value,
          0,
          this.audiotagTarget.duration
        );

        this.progressbarTarget.value = skipTo;
        this.progressbarseekTarget.value = skipTo;

        if (this.isMyUrlInMainPlayer() && this.hasAudioPlayerOutlet) {
          this.audioPlayerOutlet.audiotagTarget.currentTime = skipTo;
        }
      });
    }

    if (this.hideMainPlayerValue) {
      // Setup intersection observer to drive main player visibility
      this.observer = new IntersectionObserver((entries) => {
        if (this.isMyUrlInMainPlayer())
          entries.forEach((entry) => {
            if (entry.isIntersecting)
              this.audioPlayerOutlet.hideFixedController();
            else this.audioPlayerOutlet.showFixedController();
          });
      }, {});
      this.observer.observe(this.element);
    }

    // reset progress bar, seeker and input to whatever value we can find in queue
    const selfTime = this.getSelfTimeInQueue();
    this.updateProgressBarAndTimeDisplays(selfTime);

    // double check main player status and correct play button showing
    if (this.hasPauseiconTarget && this.hasPlayiconTarget) {
      if (
        this.hasAudioPlayerOutlet &&
        this.isMyUrlInMainPlayer() &&
        this.audioPlayerOutlet.isCurrentlyPlayingAudio()
      ) {
        this.pauseiconTarget.classList.remove("hidden");
        this.playiconTarget.classList.add("hidden");
      } else {
        this.playiconTarget.classList.remove("hidden");
        this.pauseiconTarget.classList.add("hidden");
      }
    }
  }

  checkSelfVisibility() {
    try {
      const rect = this.element.getBoundingClientRect();

      return (
        rect.top > 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  getSelfTimeInQueue() {
    try {
      const queue = this.audioPlayerOutlet.audioQueueValue;
      const myQueueObject = queue.find(
        (item) => item.url === this.audioUrlValue
      );

      if (!myQueueObject)
        return 0; // player content has not been loaded to queue
      else return myQueueObject.timeElapsed ?? 0;
    } catch (error) {
      return 0;
    }
  }
  // Adds audio file URL to end of queue
  leadPlay() {
    try {
      if (!this.hasAudioPlayerOutlet)
        throw new Error("Player is missing outlet");
      if (!this.hasAudioUrlValue)
        throw new Error("Player is missing audio url");
      // Tell plausible we have started playing
      if (!this.hasBeenSentToPlausible) {
        this.hasBeenSentToPlausible = true;
        plausible('lyssna start');
      }
      if (this.isMyUrlInMainPlayer()) {
        // if we're already the one loaded, toggle play paus on main player
        this.audioPlayerOutlet.togglePlayPause();
      } else {
        // queue our audio file
        const audioQueue = this.audioPlayerOutlet.audioQueueValue;
        const audioInQueue = audioQueue.find(
          (el) => el.url === this.audioUrlValue
        );

        if (audioInQueue) {
          // if in queue, remove from current place and add in front while keeping time played
          audioQueue.filter((el) => el.url === this.audioUrlValue);
          audioQueue.unshift(audioInQueue);
        } else {
          // add this players url to front of queue
          audioQueue.unshift({
            url: this.audioUrlValue,
            timeElapsed: this.hasProgressbarTarget
              ? this.progressbarTarget.value
              : 0,
            headline: this.audioHeadlineValue,
          });
        }

        // update main player queue with new version and play
        this.audioPlayerOutlet.audioQueueValue = audioQueue;
        this.audioPlayerOutlet.playNext();

        if (this.checkSelfVisibility() && this.hideMainPlayerValue) {
          this.audioPlayerOutlet.hideFixedController();
        }
      }

      if (!this.hideMainPlayerValue) {
        this.audioPlayerOutlet.showFixedController();
      }
    } catch (e) {
      console.error(e);
      // TODO sentry call
    }
  }

  // checks if this players url is the same as the one in the main player
  isMyUrlInMainPlayer() {
    try {
      if (!this.hasAudioUrlValue || !this.hasAudioPlayerOutlet)
        throw new Error("Missing Audio URL or player");

      return this.audioUrlValue === this.audioPlayerOutlet.audiotagTarget.src;
    } catch (e) {
      // TODO add sentry call
    }
  }
}
