import { Controller } from "@hotwired/stimulus";


export default class extends Controller {
  connect() {
    const allowedEmbedsCookie = this.getCookie("allow-embeds");
    // Filter to clear empty strings
    const allowedDomains = allowedEmbedsCookie.split('|').filter(e => e);
    allowedDomains.forEach((domain) => {
      this.activateElements(domain);
    });
  }

  setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;SameSite=Lax";
  }
  
  getCookie(cname) {
    const name = cname + "=";
    const ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  
  activateElements(domain) {
    const selector = '.embed__element[data-src*="'+domain+'"]';
    const elements = document.querySelectorAll(selector);
    elements.forEach((element) => {
        element.src = element.dataset.src;
    });
  }
  
  appendDomainToCookie(domain) {
    const allowedEmbedsCookie = this.getCookie("allow-embeds");
    const allowedDomains = allowedEmbedsCookie.split('|');
    let filteredAllowedDomains = allowedDomains.filter(e => e);
    if (filteredAllowedDomains.indexOf(domain) == -1) {
      filteredAllowedDomains.push(domain);
      const newCookieValue = filteredAllowedDomains.join("|");
      this.setCookie("allow-embeds", newCookieValue);
    }
  }

  activateEmbeds(event) {
    const domain = event.target.getAttribute("data-domain");
    this.activateElements(domain);
    event.preventDefault();
  }

  activateEmbedsAndSaveDomain(event) {
    const domain = event.target.getAttribute("data-domain");
    this.appendDomainToCookie(domain);
    this.activateElements(domain);
    event.preventDefault();
  }
}
