export const debounce = (func, delay) => {
  let timerId;
  return (...args) => {
    const boundFunc = func.bind(this, ...args);
    clearTimeout(timerId);
    timerId = setTimeout(boundFunc, delay);
  };
};

let cached = {};

export const cachedFetch = (url, options) => {
  if (!cached[url]) {
    cached[url] = fetch(url, options);
  }

  return cached[url].then((res) => res.clone());
};

// TODO check these and why they're half size
const defaultRenditionsWidths = {
  "Square-S": "160w",
  "Square-M": "330w",
  "Square-L": "500w",
  "Square-XL": "840w",
  "Portrait-S": "160w",
  "Portrait-M": "330w",
  "Portrait-L": "500w",
  "Portrait-XL": "840w",
  "Landscape-S": "160w",
  "Landscape-M": "330w",
  "Landscape-L": "500w",
  "Landscape-XL": "840w",
};

export const renditionsToSrcSet = (
  renditions,
  type = "Landscape",
  renditionsWidths = defaultRenditionsWidths
) => {
  try {
    const srcsets = [];
    const srcsetsWebP = [];
    let fallbackImage = {
      src: "",
      width: 0,
      height: 0,
    };

    Object.entries(renditions).forEach(([key, rendition]) => {
      if (key.startsWith(type) && renditionsWidths[key]) {
        if (rendition.url)
          srcsets.push(`${rendition.url} ${renditionsWidths[key]}`);
        if (rendition.url_webp)
          srcsetsWebP.push(`${rendition.url_webp} ${renditionsWidths[key]}`);
      }

      if (key == type + "-M") {
        fallbackImage = {
          src: rendition.url,
          width: rendition.width,
          height: rendition.height,
        };
      } else if (fallbackImage.src === "" && key == type + "-S") {
        fallbackImage = {
          src: rendition.url,
          width: rendition.width,
          height: rendition.height,
        };
      }
    });

    if (fallbackImage.src === "") {
      fallbackImage = {
        src: renditions.original.url,
        width: renditions.original.width,
        height: renditions.original.height,
      };
    }

    return { srcSet: srcsets, webpSet: srcsetsWebP, fallback: fallbackImage };
  } catch (error) {
    return null;
  }
};

export const srcSetToSourceTag = (srcSet, mimeType, sizes = null) => {
  const defaultSizes =
    "(min-width: 1100px) 712px, (min-width: 1000px) 748px, 100vw";

  const sizesString = sizes ? sizes : defaultSizes;
  return `
    <source
        srcset="${srcSet.join(", ")}"
        sizes="${sizesString}"
        type="${mimeType}" />
    `;
};
